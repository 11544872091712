import React from 'react'
import { graphql, Link } from 'gatsby'
import { makeStyles, Grid, Container } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Master from '../components/Master'
import Products from '../components/ListView/Products/Products'
// import Posts from '../components/ListView/Posts/Posts'

const Index = ({ data }) => {
    const page = data.page
    const products = data.allProduct.nodes
    // const posts = data.allPost.nodes
    const BUTTON_BACKGROUND_COLOR = data.BUTTON_BACKGROUND_COLOR.value
    const BUTTON_COLOR = data.BUTTON_COLOR.value

    const useStyles = makeStyles(theme => ({
        content: {
            padding: theme.spacing(0, 3, 6),
        },
        banner: {
            '& img': {
                width: '100%'
            }
        },
        center: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        },
        image: {
            width: '100px'
        },
        imageOnMediaQuery: {
            width: '100px'
        },
        button: {
            marginTop: '30px',
            padding: '10px',
            backgroundColor: `${BUTTON_BACKGROUND_COLOR}`,
            color: `${BUTTON_COLOR}`,
            textDecoration: 'none',
            fontSize: '1.3rem',
            borderRadius: '5px'
        },
        imagePaper: {
            width: '100%',
            marginTop: '10px',
            borderRadius: '4px'
        },
        paper: {
            padding: '10px'
        },
        paperLi: {
            padding: '10px',
            borderTop: 'solid 1px #EEE',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textDecoration: 'none',
            '&:first-child': {
                borderTop: 'none',
            },
            '& a': {
                textDecoration: 'none',
                color: '#555'
            },
            '& a:hover': {
                textDecoration: 'none',
                color: '#444'
            },
        }
    }));

    const classes = useStyles();

    const imageMediaQuery = useMediaQuery('(min-width:100px)');

    let image = <img className={classes.image} src="/indall-logo.png" title={page.title} alt={page.title} />
    if (!imageMediaQuery) {
        image = <img className={classes.imageOnMediaQuery} src="/indall-logo.png" title={page.title} alt={page.title} />
    }

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}`}
                noindex={false}
                nofollow={false}
                openGraph={{
                    url: `${process.env.SITE_URL}`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <div className={classes.banner}>
                <img src="https://firebasestorage.googleapis.com/v0/b/indall.appspot.com/o/images%2Flayout%2Fbanner.jpg?alt=media&token=f2ae217c-1906-485e-b23c-2586d5609be2" title={page.title} alt={page.title} />
            </div>
            <Container maxWidth="md" component="main" className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <h2>Seja bem vindo(a)!</h2>
                        <div dangerouslySetInnerHTML={{ __html: page.details }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        {/* <h2>Últimas notícias</h2>
                        <Posts posts={posts} /> */}
                        <div>
                            <img className={classes.imagePaper} src={`https://firebasestorage.googleapis.com/v0/b/indall.appspot.com/o/images%2Flayout%2Fservicos.jpg?alt=media&token=b6efcbc0-24db-4d1d-a515-e43bf2f1b8bd`} title={page.title} alt={page.title} />
                        </div>
                    </Grid>
                </Grid>
                <h2 className={classes.center}>Produtos em destaque</h2>
                <Products products={products} />
                <div className={classes.center}>
                    <Link className={classes.button} to="/produtos">veja todos os produtos</Link>
                </div>
                <h1 className={classes.center}>
                        {image}
                </h1>
            </Container>
        </Master>
    )
}

export default Index

export const query = graphql`
    query ($pageId: String!) {
        page(id: {eq: $pageId}) {
            title
            description
            keywords
            details
        }
        allProduct(sort: {fields: updatedAt, order: DESC}, filter: {isFeatured: {eq: true}}, limit: 6) {
          nodes {
            id
            title
            slug
            description
            price
            image            
            imageThumbnail
          }
        }
        allPost(sort: {fields: updatedAt, order: DESC}, filter: {}, limit: 5) {
            nodes {
              id
              title
              slug
              imageThumbnail
              updatedAt
            }
          }
        BUTTON_BACKGROUND_COLOR: setting(environmentName: {eq: "BUTTON_BACKGROUND_COLOR"}) {
            value
        }
        BUTTON_COLOR: setting(environmentName: {eq: "BUTTON_COLOR"}) {
            value
        }
        MENU_COLOR: setting(environmentName: {eq: "MENU_COLOR"}) {
            value
        }
    }
`