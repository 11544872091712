import React from 'react'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
// import { makeStyles } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'
// import CardActionArea from '@material-ui/core/CardActionArea'
// import CardContent from '@material-ui/core/CardContent'
// import CardMedia from '@material-ui/core/CardMedia'
// import Typography from '@material-ui/core/Typography'
import { withStyles, makeStyles, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'
import currencyFormatter from 'currency-formatter'

const Product = props => {

    const data = useStaticQuery(graphql`
        query {
            BUTTON_BACKGROUND_COLOR: setting(environmentName: {eq: "BUTTON_BACKGROUND_COLOR"}) {
                value
            }
            BUTTON_COLOR: setting(environmentName: {eq: "BUTTON_COLOR"}) {
                value
            }
            SHOW_PRICE: setting(environmentName: {eq: "SHOW_PRICE"}) {
                value
            }
            SHOW_EMPTY: setting(environmentName: {eq: "SHOW_EMPTY"}) {
                value
            }
        }
    `)
    const BUTTON_BACKGROUND_COLOR = data.BUTTON_BACKGROUND_COLOR.value
    const BUTTON_COLOR = data.BUTTON_COLOR.value
    const SHOW_PRICE = data.SHOW_PRICE.value === 'true' ? true : false
    const SHOW_EMPTY = data.SHOW_EMPTY.value

    const useStyles = makeStyles({
        card: {
            maxWidth: '100%',
        },
        button: {
            color: `${BUTTON_BACKGROUND_COLOR}`
        },
        price: {
            backgroundColor: `${BUTTON_BACKGROUND_COLOR}`,
            textTransform: 'uppercase',
            padding: '13px',
            borderRadius: '10px',
            margin: '0px 0px 0px 0px',
            color: `${BUTTON_COLOR}`,
            textDecoration: 'none',
            textAlign: 'center',
            fontSize: '1.3rem',
            display: 'block',
            border: 'none',
            '&:link': {
                textDecoration: 'none',
                color: `${BUTTON_COLOR}`
            },
            '&:visited': {
                textDecoration: 'none',
                color: `${BUTTON_COLOR}`
            },
            '&:hover': {
                textDecoration: 'none',
                color: `${BUTTON_COLOR}`
            },
            '&:active': {
                textDecoration: 'none',
                color: `${BUTTON_COLOR}`
            },
        }
    })

    const classes = useStyles()

    let price = (
        <div className={classes.price}>
            {SHOW_EMPTY}
        </div>
    )
    if (props.price > 0) {
        price = (
            <div className={classes.price}>
                {currencyFormatter.format(props.price, { locale: 'pt-BR' })}
            </div>
        )
    }

    const redirect = slug => {
        navigate(`/produtos/${slug}`)
    }

    const CssTypography = withStyles({
        root: {
            fontFamily: `${process.env.FONT_FAMILY}`
        },
    })(Typography)

    const CssCardContent = withStyles({
        root: {
            fontFamily: `${process.env.FONT_FAMILY}`,
            fontSize: `${process.env.FONT_SIZE}`,
        },
    })(CardContent)

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <Link to={`/produtos/${props.slug}`}>
                    <CardMedia
                        component="img"
                        alt={props.title}
                        image={props.imageThumbnail}
                        title={props.title}
                    />
                </Link>
                <CssCardContent onClick={() => redirect(props.slug)}>
                    <CssTypography gutterBottom variant="h5" component="h2">
                        {props.title}
                    </CssTypography>
                    {SHOW_PRICE ? price : null}
                </CssCardContent>
            </CardActionArea>
        </Card>
    )
}

export default Product